.question-audio .gray-icon-button {
  background-color: transparent;
  margin-left: 10px;
}

.question-audio .gray-icon-button:hover,
.question-audio .gray-icon-button:active,
.question-audio .gray-icon-button:focus,
.question-audio .gray-icon-button:focus:not(:hover),
.question-audio .gray-icon-button:disabled,
.question-audio .gray-icon-button[disabled] {
  background-color: #f1f3f4;
  margin-left: 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.upload-sound-button {
  font-size: 0.75rem;
  color: #7493b6;
}
