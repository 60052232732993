.choice-input-container {
  all: unset;
  flex: 1;
  padding-right: 8px;
  line-height: 1.4;
}

.choice-input-container input {
  all: unset;
  height: 100%;
  width: 100%;
  font-size: 0.875rem;
  padding-left: 5px;
}
