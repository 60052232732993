.upload-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
}

.upload-container .material-remove {
  position: absolute;
  top: 0;
  right: 18px;
  background-color: rgba(116, 147, 182, 0.8);
  border-radius: 8px;
  padding: 2px 10px;
  /* cursor: pointer; */
}

.upload-container > * {
  flex: 1 1;
}

.material-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f4;
  text-align: center;
  border-radius: 8px;
}

.material-container .upload-material-placeholder {
  font-size: 0.875rem;
  color: #7493b6;
}

.material-container .upload-material-placeholder span {
  display: block;
  text-transform: none;
}

.material-container .material-uploading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.material-container .material-uploading > span {
  display: block;
}

.material-container embed {
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 8px;
  border: 1px solid rgb(210, 214, 218);
}
