.error-desc {
  font-size: 16px;
  margin-bottom: 16px;
}

.message-error-list {
  text-align: left;
  line-height: 1.5;
  color: #f27474;
}

.message-error-item {
  font-size: 14px;
}

.justify {
  text-align: justify;
  margin-bottom: 10px;
}
