.dialog-container {
  padding: 20px 50px;
  background-color: rgb(220, 227, 231);
}

.dialog-container .dialog-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  color: rgb(51, 51, 51);
}

.dialog-container .dialog-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
}

.dialog-tabs .dialog-tab-item {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background-color: rgb(136, 136, 136);
  color: white;
  text-align: center;
  cursor: pointer;
}

.dialog-tabs .active {
  background-color: rgb(103, 188, 236);
}

.dialog-tabs .dialog-tab-item:not(:last-child) {
  margin-right: 10px;
}

.dialog-tabs .dialog-tab-item p {
  font-size: 30px;
  font-weight: 700;
  display: inline-block;
}

.dialog-container .dialog-question-wrapper .dialog-question-container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dialog-question-container .dialog-question-title {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  color: rgb(51, 51, 51);
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.dialog-question-container .dialog-question-title p {
  margin-left: 10px;
}

.dialog-question-container .dialog-question-text {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  color: rgb(51, 51, 51);
  margin-left: 10px;
  white-space: pre-wrap;
}
.dialog-question-container .dialog-text-container {
  margin-right: auto;
}

.dialog-question-container .dialog-question-image {
  height: 150px;
}

.dialog-question-container .dialog-question-image img {
  height: 150px;
  min-height: 150px;
}

.dialog-container .dialog-question-wrapper .dialog-question-choices {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dialog-question-choices .dialog-question-choice-item {
  margin-top: 30px;
  width: 436px;
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 20px 20px 20px 40px;
  display: flex;
  position: relative;
}

.dialog-question-choice-item .dialog-choice-item-char {
  position: absolute;
  left: -10px;
  top: -20px;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background-color: rgb(136, 136, 136);
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-question-choice-item .dialog-choice-item-text {
  margin-left: 10px;
  margin-right: auto;
}

.dialog-question-choice-item .dialog-choice-item-image {
  height: 100px;
}
.dialog-question-choice-item .dialog-choice-item-image img {
  height: 100px;
  min-height: 100px;
}

.dialog-question-choices .only-image {
  border-radius: 12px;
  padding: 0;
}
.dialog-question-choices .only-image .dialog-choice-item-image {
  border-radius: 12px;
  height: 200px;
  width: 100%;
}
.dialog-question-choices .only-image .dialog-choice-item-image img {
  border-radius: 12px;
  height: 200px;
  min-height: 200px;
  width: 100%;
}

.dialog-question-choices .active-choice {
  background-color: rgba(101, 187, 114, 0.12);
}

.active-choice .dialog-choice-item-char {
  background-color: rgb(101, 187, 114);
}

.dialog-container .dialog-close {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.dialog-close .close-btn {
  background-color: rgb(103, 188, 236);
  height: 50px;
  padding: 10px 20px;
  font-weight: 700;
  color: white;
  border-radius: 15px;
  cursor: pointer;
}

.dialog-container .dialog-play-btn {
  cursor: pointer;
}
