@font-face {
  font-family: "OKAFutura";
  font-weight: 400;
  src: local("OKAFuturaBook"), url(../../../../assets/fonts/OKAFuturaBook.ttf) format("truetype");
}

.page-preview {
  font-family: "OKAFutura";
  font-size: 1px;
  margin-top: 10em;
  width: 420em;
  height: 595em;
}

.page-preview .page-content {
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.page-preview .page-content .image {
  box-sizing: border-box;
}

.page-preview .page-content .image img {
  max-width: 100%;
  min-width: 100%;
  height: auto;
  object-fit: contain;
}

.page-preview .page-content .text-area {
  width: 100%;
  height: 100%;
  padding-top: 14em;
  padding-left: 28em;
  padding-bottom: 28em;
  padding-right: 28em;
  display: flex;
  align-items: stretch;
  letter-spacing: normal !important;
}
.page-preview .page-content .text-area .page-number {
  min-width: 14em;
  width: 14em;
  position: relative;
  line-height: 1;
}
.page-preview .page-content .text-area .page-number span {
  position: absolute;
  bottom: 0;
  font-size: 11em;
}

.page-preview .page-content .text-area .to-left span {
  left: 0;
}

.page-preview .page-content .text-area .to-right span {
  right: 0;
}
.page-preview .page-content .text-area .text-content {
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

.page-preview .page-content .text-area .oka-futura-26 {
  font-size: 26em;
  line-height: calc(34 / 26);
}
.page-preview .page-content .text-area .oka-futura-24 {
  font-size: 24em;
  line-height: calc(32 / 24);
}
.page-preview .page-content .text-area .oka-futura-18 {
  font-size: 18em;
  line-height: calc(25 / 18);
}
.page-preview .page-content .text-area .oka-futura-14 {
  font-size: 14em;
  line-height: calc(22 / 14);
}
.page-preview .page-content .text-area .oka-futura-12 {
  font-size: 12em;
  line-height: calc(19 / 12);
}

/* Font Sizes out of OKA Standarts */
.page-preview .page-content .text-area .font-25 {
  font-size: 25em;
  line-height: calc(33 / 25);
}

.page-preview .page-content .text-area .font-22 {
  font-size: 22em;
  line-height: calc(30 / 22);
}

.page-preview .page-content .text-area .font-20 {
  font-size: 20em;
  line-height: calc(27 / 20);
}

.page-preview .page-content .text-area .font-16 {
  font-size: 25em;
  line-height: calc(23 / 16);
}

@media screen and (max-width: 319.98px) {
  /* no page preview */
  .page-preview {
    display: none;
  }
}
@media screen and (min-width: 320px) {
  /* page preview width: 224px */
  .page-preview {
    font-size: calc(224px / 420);
  }
}
@media screen and (min-width: 576px) {
  /* page preview width: 480px */
  .page-preview {
    font-size: calc(480px / 420);
  }
}
@media screen and (min-width: 768px) {
  /* page preview width: 672px */
  .page-preview {
    font-size: calc(672px / 420);
  }
}
@media screen and (min-width: 992px) {
  /* page preview width: 360px */
  .page-preview {
    font-size: calc(360px / 420);
  }
}
@media screen and (min-width: 1200px) {
  /* page preview width: 333px */
  .page-preview {
    font-size: calc(333px / 420);
  }
}

@media screen and (min-width: 1400px) {
  /* page preview width: 416px */
  .page-preview {
    font-size: calc(416px / 420);
  }
}

/*
  EĞER media query'lerde sıkıntı çıkarsa
  @media screen and (min-width: 992px) and (max-width: 1199.98px)
  gibi işlemler yapabiliriz.
*/
